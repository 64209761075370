@mixin d {
  @media only screen and (max-width: "1339px") {
    @content; } }
@mixin t {
  @media only screen and (max-width: "1023px") {
    @content; } }
@mixin m {
  @media only screen and (max-width: "767px") {
    @content; } }
@mixin a {
  @media only screen and (max-width: "666px") {
    @content; } }
@mixin s {
  @media only screen and (max-width: "474px") {
    @content; } }

@include font(verdana, verdana);
@mixin f {
  font-family: 'verdana', serif; }

@include font(verdana-bold, verdana-bold);
@mixin fb {
  font-family: 'verdana-bold', serif; }

$black: #000;
$white: #fff;

body {
  min-width: 320px;
  @include f;
  font-size: 16px;
  line-height: (24/16);
  color: $black;
  &.no-scroll {
    overflow: hidden; } }

body,
button,
input,
textarea,
select {}

a {
  text-decoration: none; }

button {
  background: none; }

.out {
  padding: 40px 0;
  @include t {
    padding: 20px 0;
    overflow: hidden; }
  @include m {
    padding: 13px 0; } }

.center {
  max-width: 1340px;
  margin: 0 auto;
  padding: 0 30px;
  @include t {
    padding: 0 20px; }
  @include m {
    padding: 0 15px; } }

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &__item {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-right: 10px; }
    &:not(:last-child) {
      position: relative;
      &:after {
        content: ">";
        position: relative;
        font-size: 16px;
        margin-left: 10px; } } }
  &__link {
    font-size: 16px;
    color: $black; } }

.section {
  margin-bottom: 80px;
  @include t {
    position: relative;
    margin-bottom: 50px; }
  @include m {
    margin-bottom: 40px; }
  &__aside {
    position: fixed;
    width: 240px;
    padding-right: 20px;
    @include d {
      width: 210px; }
    @include t {
      position: relative;
      display: flex;
      align-items: center;
      flex: 0 0 100%;
      width: 100%;
      margin-bottom: 40px;
      padding: 0; }
    @include m {
      margin-bottom: 30px; } }
  &__container {
    padding-left: 240px;
    @include d {
      padding-left: 210px; }
    @include t {
      padding-left: 0; } }
  &__logo {
    display: block;
    margin-bottom: 30px;
    font-size: 0;
    @include t {
      margin: 0 auto 0 0; } }
  &__logo &__pic {
    max-width: 175px;
    @include t {
      max-width: 140px; }
    @include m {
      max-width: 190px; }
    &_mobile {
      display: none;
      @include m {
        display: inline-block; } }
    &_desktop {
      @include m {
        display: none; } } }
  &__nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include t {
      position: absolute;
      top: 55px;
      left: -20px;
      right: -20px;
      z-index: 3;
      justify-content: center;
      align-items: center;
      min-height: calc(100vh - 75px);
      padding: 30px 30px 80px;
      background: $white;
      transform: translateX(100%);
      opacity: 0;
      transition: all .5s;
      &.visible {
        transform: translateX(0);
        opacity: 1; } }
    @include m {
      min-height: calc(100vh - 32px);
      left: -15px;
      right: -15px;
      padding: 30px 20px 60px; } }
  &__link {
    font-family: 'Arial', serif;
    font-size: 16px;
    font-weight: 700;
    color: #012258;
    transition: color .3s;
    @include t {
      font-size: 20px; }
    @include m {
      font-size: 16px; }
    &:hover {
      color: lighten(#012258, 20); }
    &.active {
      text-decoration: underline; }
    &:not(:last-child) {
      margin-bottom: 28px;
      @include m {
        margin-bottom: 20px; } } }
  &__title {
    margin-bottom: 20px;
    font-family: 'Arial', serif;
    font-size: 50px;
    line-height: 1;
    font-weight: 700;
    color: rgba(#0a0127, .95);
    @include d {
      font-size: 40px; }
    @include m {
      font-size: 34px; }
    @include s {
      font-size: 28px; } }
  &__breadcrumbs {
    margin-bottom: 15px;
    @include m {
      margin-bottom: 12px; } }
  &__wrap {
    padding: 10px 10px 0;
    border: 1px solid #bbbdcf;
    @include m {
      padding: 5px 5px 0; } } }

.burger {
  position: relative;
  z-index: 10;
  display: none;
  width: 32px;
  height: 32px;
  text-align: center;
  transition: background .3s;
  @include t {
    display: block; }
  &:before,
  &:after,
  span {
    display: block;
    width: 30px;
    height: 3px;
    background: #012258;
    transition: transform .3s, width .3s, background .3s;
    @include m {
      width: 24px;
      height: 2px; } }
  &:before,
  &:after {
    content: '';
    margin: 0 auto; }
  span {
    margin: 5px auto;
    @include m {
      margin: 4px auto; } }
  &.active {
    &:before {
      transform: translateY(8px) rotate(45deg);
      @include m {
        transform: translateY(7px) rotate(45deg); } }
    span {
      transform: rotate(-45deg); }
    &:after {
      transform: translateY(-8px) rotate(45deg);
      @include m {
        transform: translateY(-6px) rotate(45deg); } } } }

.company {
  margin-bottom: 10px;
  &__table {
    display: flex;
    margin-bottom: 40px;
    background-color: #eff4f5;
    font-size: 14px;
    color: #0a0127;
    @include d {
      display: block;
      padding-top: 15px;
      background: none; }
    @include s {
      margin-bottom: 25px; } }
  &__col:first-child {
    display: flex;
    flex-direction: column;
    flex: 0 0 100px;
    width: 100px;
    padding-right: 10px;
    background: $white;
    @include d {
      align-items: center;
      width: 100%;
      margin-bottom: 25px;
      padding: 0;
      background: none;
      text-align: center; } }
  &__col:nth-child(2) {
    flex: 0 0 43%;
    width: 43%;
    @include d {
      flex: 0 0 100%;
      width: 100%;
      background-color: #eff4f5; } }
  &__col:nth-child(3) {
    flex: 0 0 47%;
    width: 47%;
    @include d {
      flex: 0 0 100%;
      width: 100%;
      background-color: #eff4f5; } }
  &__logo {
    display: block;
    margin-bottom: auto;
    font-size: 0;
    @include d {
      margin: 0 0 20px 0; }
    @include m {
      margin-bottom: 15px; } }
  &__pic {
    width: 100%;
    @include d {
      width: 150px; }
    @include a {
      width: 100px; } }
  &__line {
    display: flex;
    @include s {
      display: block;
      text-align: center; } }
  &__category {
    flex: 0 0 48%;
    width: 48%;
    padding: 4px 7px;
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: $white;
    background-color: #cfe0e4;
    text-align: right;
    font-family: 'Arial', serif;
    font-weight: 700;
    @include s {
      flex: 0 0 100%;
      width: 100%;
      text-align: center; } }
  &__line:last-child &__category {
    border-bottom: none; }
  &__value {
    flex-grow: 1;
    padding: 4px 10px; }

  &__value &__link {
    @include fb;
    text-decoration: underline;
    color: #093d9b;
    &:hover {
      text-decoration: none; } }
  &__foot {
    text-align: center; }
  &__foot &__link {
    @include fb;
    font-size: 18px;
    text-decoration: underline;
    color: #0700ac;
    &:hover {
      text-decoration: none; } }
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 54px;
    background-color: #ffb310;
    border-radius: 5px;
    text-align: center;
    font-family: 'Arial', serif;
    font-size: 21px;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 700;
    color: rgba($black, .95);
    transition: all .3s;
    @include d {
      width: 250px;
      height: 45px; }
    @include a {
      width: 200px;
      height: 40px; }
    // +m
    //   text-align: center
    //   font-size: 16px
    //   line-height: 1
    &:hover {
      background-color: lighten(#ffb310, 10); } } }

.desc {
  &__head {
    padding: 5px 10px;
    background-color: #eff4f5;
    border: 1px solid #e7e8ee;
    font-family: 'Arial', serif;
    font-size: 24px;
    line-height: 1;
    font-weight: 700;
    color: rgba($black, .95);
    @include m {
      padding: 3px 5px;
      font-size: 18px; } }
  &__body {
    padding: 30px 30px 40px 0;
    @include t {
      padding-right: 0; }
    @include m {
      padding: 20px 0 25px; }
    @include s {
      padding-top: 15px; }
    &_pr0 {
      padding-right: 0; } }
  &__row {
    display: flex;
    margin: 0 -15px;
    color: rgba($black, .9);
    @include m {
      font-size: 14px; }
    @include a {
      display: block;
      margin: 0; } }
  &__col {
    margin: 0 15px;
    @include a {
      margin: 0; }
    &:first-child {
      flex: 0 0 calc(46% - 30px);
      width: calc(46% - 30px);
      @include a {
        flex: 0 0 100%;
        width: 100%;
        margin-bottom: 20px; } }
    &:nth-child(2) {
      flex: 0 0 calc(54% - 30px);
      width: calc(54% - 30px);
      @include a {
        flex: 0 0 100%;
        width: 100%; } } }
  &__font {
    @include fb;
    &_mb25 {
      margin-bottom: 25px;
      @include a {
        margin-bottom: 10px; } } }
  &__box:not(:last-child) {
    margin-bottom: 30px; }
  &__category {
    margin-bottom: 20px;
    font-family: 'Arial', serif;
    line-height: 1;
    text-decoration: underline;
    font-weight: 700;
    color: rgba($black, .9);
    &_simple {
      text-decoration: none; }
    &_fs18 {
      font-size: 18px;
      @include m {
        font-size: 16px; } }
    &_mb5 {
      margin-bottom: 5px; } }
  &__box &__category {
    margin-bottom: 15px;
    font-size: 20px;
    @include m {
      font-size: 18px; } }
  &__list {
    li {
      position: relative;
      padding-left: 28px;
      @include m {
        font-size: 14px; }
      &:before {
        content: "";
        position: absolute;
        top: 3px;
        left: 0;
        width: 20px;
        height: 15px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAAtCAMAAADFqPh+AAABdFBMVEUCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxtgc4BtAAAAe3RSTlMAAQMGCAoLDA0PEBITFBUWFxgZGhscHR4fICEiIyQlJicoKSorLC0uLzAxMjM0NTY3ODk6Ozw+P0dQW2VxeH6KkJWgo6SttLa9vr/Bw8TGx8jLzM7P0NHT1NXX2Nrb3d7f4OHi4+Tl5ufo6err7O3u7/Dx8vP09fb5/P63RTZNAAABtUlEQVQYGZ3BC0PSYBgF4CO1amrTUDRLqUzNSMsw6H6/amUlZQOdhSEVK2KKlJ0/X0TwOhi4b8+DIKYXyk+OIphkhWS6H0EkdliVGYC6K9ussSJQFd9i3SIUzTlsqEDNrEPxCEpmShSLGlTEihTPNag4/53ixSGoOGdTvDwCFZM2xZIOFWcLFK91qBj/SvFGh4rTnymWu6EimqdI9UDFWI7iXS9cDk5q6GA0R7FiwGUmyx9X0daJDQrTgMtciWT5Mto4nqVI98ElscUqJwZPwx8oMsfgEt9hjT0BD5F1itUw3EzW5cfQImJRrA2iicOGjSE0GVijsCJodpvCCsMlvEqxPoQWoWcUpoE9+jMUH4fhQVuiSOlo6EtTZEfgSU9RvDqM/4z3FJ9Oog3DpFjQ8I+xQpEbRVuDFsXTA/ir9y3FZhQdjOQoHoeAnhRF/hQ6ihYoHoa6lym+nME+pooU6U2Kwjj2dcmhJ3sCPiQr9GBPwZdrv9ji2zR8ur7LJsUYfLuxS5fSBSi4+Zt7OLNQcovCuQhFd1jnxKHsLmu256Gu6z6rygkE0fWAZCWJYEL3fprz8OMPYj1axe6uxhUAAAAASUVORK5CYII=) no-repeat 0 0 / 20px 15px; }
      &:not(:last-child) {
        margin-bottom: 7px; } }
    &:not(:last-child) {
      margin-bottom: 25px; } }
  &__text {
    @include m {
      font-size: 14px; }
    p:not(:last-child) {
      margin-bottom: 20px;
      @include m {
        margin-bottom: 15px; } }
    &:not(:last-child) {
          margin-bottom: 25px;
          @include m {
            margin-bottom: 20px; } } }
  &__link {
    text-decoration: underline;
    color: #0700ac;
    &:hover {
      text-decoration: none; }
    &_bold {
      @include fb; } }
  &__table {
    max-width: 600px;
    margin-bottom: 60px;
    table-layout: fixed;
    @include m {
      margin-bottom: 35px; }
    td {
      padding: 4px 8px;
      border: 2px solid #363636;
      @include a {
        padding: 2px 4px;
        font-size: 14px; }
      @include s {
        font-size: 12px; } } } }

.brand {
  &__item:not(:last-child) {
    margin-bottom: 30px;
    @include m {
      margin-bottom: 20px; } }
  &__category {
    margin-bottom: 3px;
    @include fb;
    font-size: 18px; }
  &__name {
    font-family: 'Arial', serif;
    font-size: 21px;
    font-weight: 700;
    text-decoration: underline;
    color: #223255;
    @include m {
      font-size: 18px; }
    &:hover {
      text-decoration: none; } }
  &__line {
    display: flex;
    border: 1px solid #bbbdcf;
    @include s {
      display: block; } }
  &__preview {
    display: flex;
    flex: 0 0 110px;
    width: 110px;
    padding: 10px;
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: #bbbdcf;
    background-color: #eff4f5;
    font-size: 0;
    @include m {
      flex: 0 0 80px;
      width: 80px; }
    @include s {
      flex: 0 0 100%;
      width: 100%;
      border-width: 0 0 1px 0; } }
  &__logo {
    display: block;
    margin: auto; }
  &__pic {
    width: 100%;
    @include s {
      width: 100px; } }
  &__control {
    flex-grow: 1;
    align-self: center;
    padding: 20px 30px;
    @include d {
      padding: 20px 15px; }
    @include m {
      padding: 10px 8px; } }
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 54px;
    background-color: #ffb310;
    border-radius: 5px;
    font-family: 'Arial', serif;
    font-size: 24px;
    font-weight: 700;
    color: rgba($black, .95);
    transition: all .3s;
    @include m {
      height: 45px;
      font-size: 18px; }
    @include a {
      text-align: center;
      font-size: 16px;
      line-height: 1; }
    @include s {
      font-size: 20px; }
    &:hover {
      background-color: lighten(#ffb310, 10); } }
  &__desc {
    flex: 0 0 500px;
    width: 500px;
    padding: 7px;
    border-left: 1px solid #bbbdcf;
    background-color: #eff4f5;
    @include d {
      flex: 0 0 55%;
      width: 55%; }
    @include m {
      flex: 0 0 60%;
      width: 60%; }
    @include s {
      flex: 0 0 100%;
      width: 100%; } }
  &__text {
    position: relative;
    padding-left: 28px;
    font-size: 14px;
    color: rgba($black, .85);
    &:before {
      content: "";
      position: absolute;
      top: 3px;
      left: 0;
      width: 20px;
      height: 15px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAAtCAMAAADFqPh+AAABdFBMVEUCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxtgc4BtAAAAe3RSTlMAAQMGCAoLDA0PEBITFBUWFxgZGhscHR4fICEiIyQlJicoKSorLC0uLzAxMjM0NTY3ODk6Ozw+P0dQW2VxeH6KkJWgo6SttLa9vr/Bw8TGx8jLzM7P0NHT1NXX2Nrb3d7f4OHi4+Tl5ufo6err7O3u7/Dx8vP09fb5/P63RTZNAAABtUlEQVQYGZ3BC0PSYBgF4CO1amrTUDRLqUzNSMsw6H6/amUlZQOdhSEVK2KKlJ0/X0TwOhi4b8+DIKYXyk+OIphkhWS6H0EkdliVGYC6K9ussSJQFd9i3SIUzTlsqEDNrEPxCEpmShSLGlTEihTPNag4/53ixSGoOGdTvDwCFZM2xZIOFWcLFK91qBj/SvFGh4rTnymWu6EimqdI9UDFWI7iXS9cDk5q6GA0R7FiwGUmyx9X0daJDQrTgMtciWT5Mto4nqVI98ElscUqJwZPwx8oMsfgEt9hjT0BD5F1itUw3EzW5cfQImJRrA2iicOGjSE0GVijsCJodpvCCsMlvEqxPoQWoWcUpoE9+jMUH4fhQVuiSOlo6EtTZEfgSU9RvDqM/4z3FJ9Oog3DpFjQ8I+xQpEbRVuDFsXTA/ir9y3FZhQdjOQoHoeAnhRF/hQ6ihYoHoa6lym+nME+pooU6U2Kwjj2dcmhJ3sCPiQr9GBPwZdrv9ji2zR8ur7LJsUYfLuxS5fSBSi4+Zt7OLNQcovCuQhFd1jnxKHsLmu256Gu6z6rygkE0fWAZCWJYEL3fprz8OMPYj1axe6uxhUAAAAASUVORK5CYII=) no-repeat 0 0 / 20px 15px; }
    &:not(:last-child) {
      margin-bottom: 3px; } }
  &__rating {
    @include fb;
    color: rgba(#050505, .85); }
  &__link {
    white-space: nowrap;
    @include fb;
    text-decoration: underline;
    color: rgba(#021796, .85);
    &:hover {
      text-decoration: none; } } }

.footer {
  &__wrap {
    display: flex;
    padding: 45px 0 50px;
    background-color: #eff4f5;
    border: 1px solid #e7e8ee;
    @include t {
      flex-wrap: wrap;
      padding: 30px 20px; } }
  &__copyright {
    flex: 0 0 240px;
    width: 240px;
    padding: 0 20px 0 60px;
    @include d {
      flex: 0 0 210px;
      width: 210px;
      padding: 0 20px; }
    @include t {
      flex: 0 0 100%;
      width: 100%;
      order: 4;
      text-align: center; } }
  &__box {
    flex-grow: 1;
    padding-right: 50px;
    @include t {
      margin-bottom: 20px;
      padding-right: 0; } }
  &__menu {
    display: flex;
    margin-bottom: 15px;
    @include t {
      justify-content: space-between; }
    @include m {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px; } }
  &__link {
    font-family: 'Arial', serif;
    font-weight: 700;
    color: #012258;
    transition: all .3s;
    &:hover {
      color: lighten(#012258, 12); }
    &:not(:last-child) {
      margin-right: 43px;
      @include d {
        margin-right: 20px; }
      @include m {
        margin: 0 0 10px 0; } } }
  &__line {
    display: flex;
    align-items: flex-end;
    @include m {
      flex-direction: column;
      align-items: center; } }
  &__info {
    margin-right: auto;
    @include m {
      margin: 0 0 15px 0;
      text-align: center; } }
  &__mail {
    @include fb;
    color: #012258;
    transition: color .3s;
    &:hover {
      color: lighten(#012258, 12); } } }






