html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img, fieldset, a img {
  border: none;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
}

input[type="submit"],
button {
  cursor: pointer;
}

input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

input, button {
  margin: 0;
  padding: 0;
  border: 0;
}

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none;
}

ul, ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: #000;
}

@font-face {
  font-family: verdana;
  src: url("fonts/verdana.woff2") format("woff2"), url("fonts/verdana.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: verdana-bold;
  src: url("fonts/verdana-bold.woff2") format("woff2"), url("fonts/verdana-bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  min-width: 320px;
  font-family: 'verdana', serif;
  font-size: 16px;
  line-height: 1.5;
  color: #000;
}

body.no-scroll {
  overflow: hidden;
}

a {
  text-decoration: none;
}

button {
  background: none;
}

.out {
  padding: 40px 0;
}

@media only screen and (max-width: 1023px) {
  .out {
    padding: 20px 0;
    overflow: hidden;
  }
}

@media only screen and (max-width: 767px) {
  .out {
    padding: 13px 0;
  }
}

.center {
  max-width: 1340px;
  margin: 0 auto;
  padding: 0 30px;
}

@media only screen and (max-width: 1023px) {
  .center {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .center {
    padding: 0 15px;
  }
}

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.breadcrumbs__item {
  display: flex;
  align-items: center;
}

.breadcrumbs__item:not(:last-child) {
  margin-right: 10px;
}

.breadcrumbs__item:not(:last-child) {
  position: relative;
}

.breadcrumbs__item:not(:last-child):after {
  content: ">";
  position: relative;
  font-size: 16px;
  margin-left: 10px;
}

.breadcrumbs__link {
  font-size: 16px;
  color: #000;
}

.section {
  margin-bottom: 80px;
}

@media only screen and (max-width: 1023px) {
  .section {
    position: relative;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section {
    margin-bottom: 40px;
  }
}

.section__aside {
  position: fixed;
  width: 240px;
  padding-right: 20px;
}

@media only screen and (max-width: 1339px) {
  .section__aside {
    width: 210px;
  }
}

@media only screen and (max-width: 1023px) {
  .section__aside {
    position: relative;
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 40px;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .section__aside {
    margin-bottom: 30px;
  }
}

.section__container {
  padding-left: 240px;
}

@media only screen and (max-width: 1339px) {
  .section__container {
    padding-left: 210px;
  }
}

@media only screen and (max-width: 1023px) {
  .section__container {
    padding-left: 0;
  }
}

.section__logo {
  display: block;
  margin-bottom: 30px;
  font-size: 0;
}

@media only screen and (max-width: 1023px) {
  .section__logo {
    margin: 0 auto 0 0;
  }
}

.section__logo .section__pic {
  max-width: 175px;
}

@media only screen and (max-width: 1023px) {
  .section__logo .section__pic {
    max-width: 140px;
  }
}

@media only screen and (max-width: 767px) {
  .section__logo .section__pic {
    max-width: 190px;
  }
}

.section__logo .section__pic_mobile {
  display: none;
}

@media only screen and (max-width: 767px) {
  .section__logo .section__pic_mobile {
    display: inline-block;
  }
}

@media only screen and (max-width: 767px) {
  .section__logo .section__pic_desktop {
    display: none;
  }
}

.section__nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media only screen and (max-width: 1023px) {
  .section__nav {
    position: absolute;
    top: 55px;
    left: -20px;
    right: -20px;
    z-index: 3;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 75px);
    padding: 30px 30px 80px;
    background: #fff;
    transform: translateX(100%);
    opacity: 0;
    transition: all .5s;
  }
  .section__nav.visible {
    transform: translateX(0);
    opacity: 1;
  }
}

@media only screen and (max-width: 767px) {
  .section__nav {
    min-height: calc(100vh - 32px);
    left: -15px;
    right: -15px;
    padding: 30px 20px 60px;
  }
}

.section__link {
  font-family: 'Arial', serif;
  font-size: 16px;
  font-weight: 700;
  color: #012258;
  transition: color .3s;
}

@media only screen and (max-width: 1023px) {
  .section__link {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .section__link {
    font-size: 16px;
  }
}

.section__link:hover {
  color: #0249bd;
}

.section__link.active {
  text-decoration: underline;
}

.section__link:not(:last-child) {
  margin-bottom: 28px;
}

@media only screen and (max-width: 767px) {
  .section__link:not(:last-child) {
    margin-bottom: 20px;
  }
}

.section__title {
  margin-bottom: 20px;
  font-family: 'Arial', serif;
  font-size: 50px;
  line-height: 1;
  font-weight: 700;
  color: rgba(10, 1, 39, 0.95);
}

@media only screen and (max-width: 1339px) {
  .section__title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section__title {
    font-size: 34px;
  }
}

@media only screen and (max-width: 474px) {
  .section__title {
    font-size: 28px;
  }
}

.section__breadcrumbs {
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .section__breadcrumbs {
    margin-bottom: 12px;
  }
}

.section__wrap {
  padding: 10px 10px 0;
  border: 1px solid #bbbdcf;
}

@media only screen and (max-width: 767px) {
  .section__wrap {
    padding: 5px 5px 0;
  }
}

.burger {
  position: relative;
  z-index: 10;
  display: none;
  width: 32px;
  height: 32px;
  text-align: center;
  transition: background .3s;
}

@media only screen and (max-width: 1023px) {
  .burger {
    display: block;
  }
}

.burger:before, .burger:after,
.burger span {
  display: block;
  width: 30px;
  height: 3px;
  background: #012258;
  transition: transform .3s, width .3s, background .3s;
}

@media only screen and (max-width: 767px) {
  .burger:before, .burger:after,
  .burger span {
    width: 24px;
    height: 2px;
  }
}

.burger:before, .burger:after {
  content: '';
  margin: 0 auto;
}

.burger span {
  margin: 5px auto;
}

@media only screen and (max-width: 767px) {
  .burger span {
    margin: 4px auto;
  }
}

.burger.active:before {
  transform: translateY(8px) rotate(45deg);
}

@media only screen and (max-width: 767px) {
  .burger.active:before {
    transform: translateY(7px) rotate(45deg);
  }
}

.burger.active span {
  transform: rotate(-45deg);
}

.burger.active:after {
  transform: translateY(-8px) rotate(45deg);
}

@media only screen and (max-width: 767px) {
  .burger.active:after {
    transform: translateY(-6px) rotate(45deg);
  }
}

.company {
  margin-bottom: 10px;
}

.company__table {
  display: flex;
  margin-bottom: 40px;
  background-color: #eff4f5;
  font-size: 14px;
  color: #0a0127;
}

@media only screen and (max-width: 1339px) {
  .company__table {
    display: block;
    padding-top: 15px;
    background: none;
  }
}

@media only screen and (max-width: 474px) {
  .company__table {
    margin-bottom: 25px;
  }
}

.company__col:first-child {
  display: flex;
  flex-direction: column;
  flex: 0 0 100px;
  width: 100px;
  padding-right: 10px;
  background: #fff;
}

@media only screen and (max-width: 1339px) {
  .company__col:first-child {
    align-items: center;
    width: 100%;
    margin-bottom: 25px;
    padding: 0;
    background: none;
    text-align: center;
  }
}

.company__col:nth-child(2) {
  flex: 0 0 43%;
  width: 43%;
}

@media only screen and (max-width: 1339px) {
  .company__col:nth-child(2) {
    flex: 0 0 100%;
    width: 100%;
    background-color: #eff4f5;
  }
}

.company__col:nth-child(3) {
  flex: 0 0 47%;
  width: 47%;
}

@media only screen and (max-width: 1339px) {
  .company__col:nth-child(3) {
    flex: 0 0 100%;
    width: 100%;
    background-color: #eff4f5;
  }
}

.company__logo {
  display: block;
  margin-bottom: auto;
  font-size: 0;
}

@media only screen and (max-width: 1339px) {
  .company__logo {
    margin: 0 0 20px 0;
  }
}

@media only screen and (max-width: 767px) {
  .company__logo {
    margin-bottom: 15px;
  }
}

.company__pic {
  width: 100%;
}

@media only screen and (max-width: 1339px) {
  .company__pic {
    width: 150px;
  }
}

@media only screen and (max-width: 666px) {
  .company__pic {
    width: 100px;
  }
}

.company__line {
  display: flex;
}

@media only screen and (max-width: 474px) {
  .company__line {
    display: block;
    text-align: center;
  }
}

.company__category {
  flex: 0 0 48%;
  width: 48%;
  padding: 4px 7px;
  border-width: 0 1px 1px;
  border-style: solid;
  border-color: #fff;
  background-color: #cfe0e4;
  text-align: right;
  font-family: 'Arial', serif;
  font-weight: 700;
}

@media only screen and (max-width: 474px) {
  .company__category {
    flex: 0 0 100%;
    width: 100%;
    text-align: center;
  }
}

.company__line:last-child .company__category {
  border-bottom: none;
}

.company__value {
  flex-grow: 1;
  padding: 4px 10px;
}

.company__value .company__link {
  font-family: 'verdana-bold', serif;
  text-decoration: underline;
  color: #093d9b;
}

.company__value .company__link:hover {
  text-decoration: none;
}

.company__foot {
  text-align: center;
}

.company__foot .company__link {
  font-family: 'verdana-bold', serif;
  font-size: 18px;
  text-decoration: underline;
  color: #0700ac;
}

.company__foot .company__link:hover {
  text-decoration: none;
}

.company__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 54px;
  background-color: #ffb310;
  border-radius: 5px;
  text-align: center;
  font-family: 'Arial', serif;
  font-size: 21px;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.95);
  transition: all .3s;
}

@media only screen and (max-width: 1339px) {
  .company__btn {
    width: 250px;
    height: 45px;
  }
}

@media only screen and (max-width: 666px) {
  .company__btn {
    width: 200px;
    height: 40px;
  }
}

.company__btn:hover {
  background-color: #ffc343;
}

.desc__head {
  padding: 5px 10px;
  background-color: #eff4f5;
  border: 1px solid #e7e8ee;
  font-family: 'Arial', serif;
  font-size: 24px;
  line-height: 1;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.95);
}

@media only screen and (max-width: 767px) {
  .desc__head {
    padding: 3px 5px;
    font-size: 18px;
  }
}

.desc__body {
  padding: 30px 30px 40px 0;
}

@media only screen and (max-width: 1023px) {
  .desc__body {
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .desc__body {
    padding: 20px 0 25px;
  }
}

@media only screen and (max-width: 474px) {
  .desc__body {
    padding-top: 15px;
  }
}

.desc__body_pr0 {
  padding-right: 0;
}

.desc__row {
  display: flex;
  margin: 0 -15px;
  color: rgba(0, 0, 0, 0.9);
}

@media only screen and (max-width: 767px) {
  .desc__row {
    font-size: 14px;
  }
}

@media only screen and (max-width: 666px) {
  .desc__row {
    display: block;
    margin: 0;
  }
}

.desc__col {
  margin: 0 15px;
}

@media only screen and (max-width: 666px) {
  .desc__col {
    margin: 0;
  }
}

.desc__col:first-child {
  flex: 0 0 calc(46% - 30px);
  width: calc(46% - 30px);
}

@media only screen and (max-width: 666px) {
  .desc__col:first-child {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 20px;
  }
}

.desc__col:nth-child(2) {
  flex: 0 0 calc(54% - 30px);
  width: calc(54% - 30px);
}

@media only screen and (max-width: 666px) {
  .desc__col:nth-child(2) {
    flex: 0 0 100%;
    width: 100%;
  }
}

.desc__font {
  font-family: 'verdana-bold', serif;
}

.desc__font_mb25 {
  margin-bottom: 25px;
}

@media only screen and (max-width: 666px) {
  .desc__font_mb25 {
    margin-bottom: 10px;
  }
}

.desc__box:not(:last-child) {
  margin-bottom: 30px;
}

.desc__category {
  margin-bottom: 20px;
  font-family: 'Arial', serif;
  line-height: 1;
  text-decoration: underline;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.9);
}

.desc__category_simple {
  text-decoration: none;
}

.desc__category_fs18 {
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  .desc__category_fs18 {
    font-size: 16px;
  }
}

.desc__category_mb5 {
  margin-bottom: 5px;
}

.desc__box .desc__category {
  margin-bottom: 15px;
  font-size: 20px;
}

@media only screen and (max-width: 767px) {
  .desc__box .desc__category {
    font-size: 18px;
  }
}

.desc__list li {
  position: relative;
  padding-left: 28px;
}

@media only screen and (max-width: 767px) {
  .desc__list li {
    font-size: 14px;
  }
}

.desc__list li:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 0;
  width: 20px;
  height: 15px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAAtCAMAAADFqPh+AAABdFBMVEUCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxtgc4BtAAAAe3RSTlMAAQMGCAoLDA0PEBITFBUWFxgZGhscHR4fICEiIyQlJicoKSorLC0uLzAxMjM0NTY3ODk6Ozw+P0dQW2VxeH6KkJWgo6SttLa9vr/Bw8TGx8jLzM7P0NHT1NXX2Nrb3d7f4OHi4+Tl5ufo6err7O3u7/Dx8vP09fb5/P63RTZNAAABtUlEQVQYGZ3BC0PSYBgF4CO1amrTUDRLqUzNSMsw6H6/amUlZQOdhSEVK2KKlJ0/X0TwOhi4b8+DIKYXyk+OIphkhWS6H0EkdliVGYC6K9ussSJQFd9i3SIUzTlsqEDNrEPxCEpmShSLGlTEihTPNag4/53ixSGoOGdTvDwCFZM2xZIOFWcLFK91qBj/SvFGh4rTnymWu6EimqdI9UDFWI7iXS9cDk5q6GA0R7FiwGUmyx9X0daJDQrTgMtciWT5Mto4nqVI98ElscUqJwZPwx8oMsfgEt9hjT0BD5F1itUw3EzW5cfQImJRrA2iicOGjSE0GVijsCJodpvCCsMlvEqxPoQWoWcUpoE9+jMUH4fhQVuiSOlo6EtTZEfgSU9RvDqM/4z3FJ9Oog3DpFjQ8I+xQpEbRVuDFsXTA/ir9y3FZhQdjOQoHoeAnhRF/hQ6ihYoHoa6lym+nME+pooU6U2Kwjj2dcmhJ3sCPiQr9GBPwZdrv9ji2zR8ur7LJsUYfLuxS5fSBSi4+Zt7OLNQcovCuQhFd1jnxKHsLmu256Gu6z6rygkE0fWAZCWJYEL3fprz8OMPYj1axe6uxhUAAAAASUVORK5CYII=) no-repeat 0 0/20px 15px;
}

.desc__list li:not(:last-child) {
  margin-bottom: 7px;
}

.desc__list:not(:last-child) {
  margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
  .desc__text {
    font-size: 14px;
  }
}

.desc__text p:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .desc__text p:not(:last-child) {
    margin-bottom: 15px;
  }
}

.desc__text:not(:last-child) {
  margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
  .desc__text:not(:last-child) {
    margin-bottom: 20px;
  }
}

.desc__link {
  text-decoration: underline;
  color: #0700ac;
}

.desc__link:hover {
  text-decoration: none;
}

.desc__link_bold {
  font-family: 'verdana-bold', serif;
}

.desc__table {
  max-width: 600px;
  margin-bottom: 60px;
  table-layout: fixed;
}

@media only screen and (max-width: 767px) {
  .desc__table {
    margin-bottom: 35px;
  }
}

.desc__table td {
  padding: 4px 8px;
  border: 2px solid #363636;
}

@media only screen and (max-width: 666px) {
  .desc__table td {
    padding: 2px 4px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 474px) {
  .desc__table td {
    font-size: 12px;
  }
}

.brand__item:not(:last-child) {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .brand__item:not(:last-child) {
    margin-bottom: 20px;
  }
}

.brand__category {
  margin-bottom: 3px;
  font-family: 'verdana-bold', serif;
  font-size: 18px;
}

.brand__name {
  font-family: 'Arial', serif;
  font-size: 21px;
  font-weight: 700;
  text-decoration: underline;
  color: #223255;
}

@media only screen and (max-width: 767px) {
  .brand__name {
    font-size: 18px;
  }
}

.brand__name:hover {
  text-decoration: none;
}

.brand__line {
  display: flex;
  border: 1px solid #bbbdcf;
}

@media only screen and (max-width: 474px) {
  .brand__line {
    display: block;
  }
}

.brand__preview {
  display: flex;
  flex: 0 0 110px;
  width: 110px;
  padding: 10px;
  border-width: 0 1px 0 0;
  border-style: solid;
  border-color: #bbbdcf;
  background-color: #eff4f5;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .brand__preview {
    flex: 0 0 80px;
    width: 80px;
  }
}

@media only screen and (max-width: 474px) {
  .brand__preview {
    flex: 0 0 100%;
    width: 100%;
    border-width: 0 0 1px 0;
  }
}

.brand__logo {
  display: block;
  margin: auto;
}

.brand__pic {
  width: 100%;
}

@media only screen and (max-width: 474px) {
  .brand__pic {
    width: 100px;
  }
}

.brand__control {
  flex-grow: 1;
  align-self: center;
  padding: 20px 30px;
}

@media only screen and (max-width: 1339px) {
  .brand__control {
    padding: 20px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .brand__control {
    padding: 10px 8px;
  }
}

.brand__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 54px;
  background-color: #ffb310;
  border-radius: 5px;
  font-family: 'Arial', serif;
  font-size: 24px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.95);
  transition: all .3s;
}

@media only screen and (max-width: 767px) {
  .brand__btn {
    height: 45px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 666px) {
  .brand__btn {
    text-align: center;
    font-size: 16px;
    line-height: 1;
  }
}

@media only screen and (max-width: 474px) {
  .brand__btn {
    font-size: 20px;
  }
}

.brand__btn:hover {
  background-color: #ffc343;
}

.brand__desc {
  flex: 0 0 500px;
  width: 500px;
  padding: 7px;
  border-left: 1px solid #bbbdcf;
  background-color: #eff4f5;
}

@media only screen and (max-width: 1339px) {
  .brand__desc {
    flex: 0 0 55%;
    width: 55%;
  }
}

@media only screen and (max-width: 767px) {
  .brand__desc {
    flex: 0 0 60%;
    width: 60%;
  }
}

@media only screen and (max-width: 474px) {
  .brand__desc {
    flex: 0 0 100%;
    width: 100%;
  }
}

.brand__text {
  position: relative;
  padding-left: 28px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}

.brand__text:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 0;
  width: 20px;
  height: 15px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAAtCAMAAADFqPh+AAABdFBMVEUCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxsCsxtgc4BtAAAAe3RSTlMAAQMGCAoLDA0PEBITFBUWFxgZGhscHR4fICEiIyQlJicoKSorLC0uLzAxMjM0NTY3ODk6Ozw+P0dQW2VxeH6KkJWgo6SttLa9vr/Bw8TGx8jLzM7P0NHT1NXX2Nrb3d7f4OHi4+Tl5ufo6err7O3u7/Dx8vP09fb5/P63RTZNAAABtUlEQVQYGZ3BC0PSYBgF4CO1amrTUDRLqUzNSMsw6H6/amUlZQOdhSEVK2KKlJ0/X0TwOhi4b8+DIKYXyk+OIphkhWS6H0EkdliVGYC6K9ussSJQFd9i3SIUzTlsqEDNrEPxCEpmShSLGlTEihTPNag4/53ixSGoOGdTvDwCFZM2xZIOFWcLFK91qBj/SvFGh4rTnymWu6EimqdI9UDFWI7iXS9cDk5q6GA0R7FiwGUmyx9X0daJDQrTgMtciWT5Mto4nqVI98ElscUqJwZPwx8oMsfgEt9hjT0BD5F1itUw3EzW5cfQImJRrA2iicOGjSE0GVijsCJodpvCCsMlvEqxPoQWoWcUpoE9+jMUH4fhQVuiSOlo6EtTZEfgSU9RvDqM/4z3FJ9Oog3DpFjQ8I+xQpEbRVuDFsXTA/ir9y3FZhQdjOQoHoeAnhRF/hQ6ihYoHoa6lym+nME+pooU6U2Kwjj2dcmhJ3sCPiQr9GBPwZdrv9ji2zR8ur7LJsUYfLuxS5fSBSi4+Zt7OLNQcovCuQhFd1jnxKHsLmu256Gu6z6rygkE0fWAZCWJYEL3fprz8OMPYj1axe6uxhUAAAAASUVORK5CYII=) no-repeat 0 0/20px 15px;
}

.brand__text:not(:last-child) {
  margin-bottom: 3px;
}

.brand__rating {
  font-family: 'verdana-bold', serif;
  color: rgba(5, 5, 5, 0.85);
}

.brand__link {
  white-space: nowrap;
  font-family: 'verdana-bold', serif;
  text-decoration: underline;
  color: rgba(2, 23, 150, 0.85);
}

.brand__link:hover {
  text-decoration: none;
}

.footer__wrap {
  display: flex;
  padding: 45px 0 50px;
  background-color: #eff4f5;
  border: 1px solid #e7e8ee;
}

@media only screen and (max-width: 1023px) {
  .footer__wrap {
    flex-wrap: wrap;
    padding: 30px 20px;
  }
}

.footer__copyright {
  flex: 0 0 240px;
  width: 240px;
  padding: 0 20px 0 60px;
}

@media only screen and (max-width: 1339px) {
  .footer__copyright {
    flex: 0 0 210px;
    width: 210px;
    padding: 0 20px;
  }
}

@media only screen and (max-width: 1023px) {
  .footer__copyright {
    flex: 0 0 100%;
    width: 100%;
    order: 4;
    text-align: center;
  }
}

.footer__box {
  flex-grow: 1;
  padding-right: 50px;
}

@media only screen and (max-width: 1023px) {
  .footer__box {
    margin-bottom: 20px;
    padding-right: 0;
  }
}

.footer__menu {
  display: flex;
  margin-bottom: 15px;
}

@media only screen and (max-width: 1023px) {
  .footer__menu {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 767px) {
  .footer__menu {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
}

.footer__link {
  font-family: 'Arial', serif;
  font-weight: 700;
  color: #012258;
  transition: all .3s;
}

.footer__link:hover {
  color: #023995;
}

.footer__link:not(:last-child) {
  margin-right: 43px;
}

@media only screen and (max-width: 1339px) {
  .footer__link:not(:last-child) {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .footer__link:not(:last-child) {
    margin: 0 0 10px 0;
  }
}

.footer__line {
  display: flex;
  align-items: flex-end;
}

@media only screen and (max-width: 767px) {
  .footer__line {
    flex-direction: column;
    align-items: center;
  }
}

.footer__info {
  margin-right: auto;
}

@media only screen and (max-width: 767px) {
  .footer__info {
    margin: 0 0 15px 0;
    text-align: center;
  }
}

.footer__mail {
  font-family: 'verdana-bold', serif;
  color: #012258;
  transition: color .3s;
}

.footer__mail:hover {
  color: #023995;
}
